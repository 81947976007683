import "./particles.css"

function Particles(props) {


    return ( <div className="wrapper">
    <div className="particle partical1"></div>
    <div className="particle partical2"></div>
    <div className="particle partical3"></div>
    <div className="particle partical4"></div>
    
    </div> );
}

export default Particles;